/*
function popup() {
  if (
    jQuery.cookie("brilliant_pop_newletter") ||
    jQuery.cookie("brilliant_pop_newletter") != null
  ) {
    $(".jsengo_popup").css("display", "none");
  } else {
    $(".jsclosepoup").on("click", function () {
      jQuery.cookie("brilliant_pop_newletter", "1", {
        expires: 1,
        path: "/",
        domain: "{{shop.url}}",
      });
      $(".jsengo_popup").css("display", "none");
    });
  }
}
*/

$(document).on("DOMContentLoaded", (e) =>
  $(".jsengo_popup").removeClass("d-none")
);
